<template>
  <component :is="employeeData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="employeeData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching employee data
      </h4>
      <div class="alert-body">
        No employee found with this employee id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-employees-list'}"
        >
          Employee List
        </b-link>
        for other employees.
      </div>
    </b-alert>

    <b-tabs
      v-if="employeeData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <employee-edit-tab-account
          :employee-data="employeeData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <employee-edit-tab-information class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <employee-edit-tab-social class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import employeeStoreModule from '../employeeStoreModule'
import EmployeeEditTabAccount from './EmployeeEditTabAccount.vue'
import EmployeeEditTabInformation from './EmployeeEditTabInformation.vue'
import EmployeeEditTabSocial from './EmployeeEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    EmployeeEditTabAccount,
    EmployeeEditTabInformation,
    EmployeeEditTabSocial,
  },
  setup() {
    const employeeData = ref(null)

    const EMPLOYEE_APP_STORE_MODULE_NAME = 'app-employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-employee/fetchEmployee', { id: router.currentRoute.params.id })
      .then(response => { employeeData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          employeeData.value = undefined
        }
      })

    return {
      employeeData,
    }
  },
}
</script>

<style>

</style>
