import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmployees(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("https://api.sams-ph.com/api/employee", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEmployee(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://api.sams-ph.com/api/employee/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/employee/employee", { employee: employeeData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
// "https://api.sams-ph.com/api/employee"
// https://api.sams-ph.com/api/employees
// http://localhost:3333/
